import * as THREE from 'three';
import React, {RefObject, useState, useCallback, useRef, useEffect} from 'react';
import {Canvas, useThree} from '@react-three/fiber';
import {OrbitControls} from '@react-three/drei';
import {XR, createXRStore} from '@react-three/xr';
import Grid from './Grid';
// import Block from './Block';
// import CameraControls from './CameraControls';
// import PlayModal from './PlayModal';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';

// import {GRID_UNIT, BRICK_HEIGHT, LEGO_COLORS} from '../constants';
// import {
//   FaPlay,
//   FaMousePointer,
//   FaSquare,
//   FaPencilAlt,
//   FaEye,
//   FaExpand,
//   FaVrCardboard,
// } from 'react-icons/fa';

import InteractiveGLBModel from './InteractiveGLBModel';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import PartList from './PartList';
import {END_POINT} from 'src/config';
import axios from 'axios';
import ActionsModel from './ActionsModel';

// interface Block {
//   type: string;
//   position: [number, number, number];
//   color: string;
// }

interface BlockNear {
  position: [number, number, number]; // Assuming position is a tuple of three numbers
}

interface CanvasBlocks {
  id: string;
  url: string;
  position: [number, number, number];
  rotation: [number, number, number];
  gltf: THREE.Group;
  description: string;
}

interface SceneContentProps {
  // blocks: Block[];
  canvasBlocks: CanvasBlocks[];
  selectedBlock: string | null;
  setSelectedBlock: (blockId: string | null) => void;
  updateBlockPosition: (
    index: number,
    newPosition: [number, number, number],
  ) => void;
  callUpdateBlockPosition: (
    index: number,
    newPosition: [number, number, number],
  ) => void;
  orbitControlsRef: ((instance: any) => void) | RefObject<any>;
  isOrbitEnabled: boolean;
  setIsOrbitEnabled: (enabled: boolean) => void;
  onDeleteBlock: (index: any) => void;
  onDuplicateBlock: (index: number) => void;
  onRotateBlock: (index: number, newPosition: [number, number, number]) => void; //onRotate: 'x' | 'z' | 'y'
}

const SceneContent: React.FC<SceneContentProps> = ({
  canvasBlocks,
  selectedBlock,
  setSelectedBlock,
  updateBlockPosition,
  callUpdateBlockPosition,
  orbitControlsRef,
  isOrbitEnabled,
  setIsOrbitEnabled,
  onDeleteBlock,
  onDuplicateBlock,
  onRotateBlock,
}) => {
  const {camera} = useThree();

  return (
    <>
      <OrbitControls
        ref={orbitControlsRef}
        camera={camera}
        enabled={isOrbitEnabled}
      />
      <Grid />
      <ambientLight intensity={0.2} />
      <directionalLight position={[10, 10, 10]} intensity={1} />

      {canvasBlocks.map((model, index) => (
        <InteractiveGLBModel
          key={model.id}
          url={model.url}
          position={model.position}
          rotation={model.rotation}
          gltf={model.gltf}
          onPositionChange={newPosition => {
            updateBlockPosition(index, newPosition);
          }}
          onRotationChange={newPosition => {
            onRotateBlock(index, newPosition);
          }}
          isSelected={selectedBlock === model.id}
          onSelect={() => setSelectedBlock(model.id)}
          setIsOrbitEnabled={setIsOrbitEnabled}
          onDelete={() => onDeleteBlock(model.id)}
          onDuplicate={() => onDuplicateBlock(index)}
          // onRotate={axis => onRotateBlock(index, axis)}
          callBlockPosition={newPosition =>
            callUpdateBlockPosition(index, newPosition)
          }
          modelFiles={canvasBlocks}
          orbitControlsRef={orbitControlsRef}
          setSelectedBlock={() => setSelectedBlock(null)}
        />
      ))}
    </>
  );
};

// const ARContent: React.FC<{
//   blocks: Block[];
//   onError: (error: any) => void;
// }> = ({blocks, onError}) => {
//   const {isPresenting, session} = useXR();

//   useEffect(() => {
//     if (session) {
//       console.log('AR session started', session);
//       session.addEventListener('end', () => console.log('AR session ended'));
//     }
//   }, [session]);

//   useEffect(() => {
//     const handleSessionError = (event: ErrorEvent) => {
//       console.error('AR session error:', event.error);
//       onError(event.error);
//     };

//     if (session) {
//       session.addEventListener('error', handleSessionError);
//     }

//     return () => {
//       if (session) {
//         session.removeEventListener('error', handleSessionError);
//       }
//     };
//   }, [session, onError]);

//   if (!isPresenting) {
//     console.log('Not presenting AR');
//     return null;
//   }

//   console.log('Rendering AR content');
//   return (
//     <>
//       <ambientLight intensity={0.5} />
//       <pointLight position={[10, 10, 10]} />
//       {blocks.map((block, index) => (
//         <Interactive key={`${block.type}-${index}`}>
//           <Block
//             type={block.type}
//             position={block.position}
//             onPositionChange={() => {}}
//             isSelected={false}
//             onSelect={() => {}}
//             blocks={blocks}
//             setIsOrbitEnabled={() => {}}
//             color={block.color}
//             onDelete={() => {}}
//             onDuplicate={() => {}}
//             onChangeColor={() => {}}
//           />
//         </Interactive>
//       ))}
//     </>
//   );
// };

interface Lesson {
  name: string;
  description: string;
  pieces: {
    filename: string;
    quantity: number;
  }[];
}

const Editor3D: React.FC = () => {
  const initialCameraPosition = new THREE.Vector3(0, 5, 0);
  // const [blocks, setBlocks] = useState<Block[]>([]);
  const [canvasBlocks, setCanvasBlocks] = useState<CanvasBlocks[]>([]);
  const [selectedBlock, setSelectedBlock] = useState<string | null>(null);
  const orbitControlsRef = useRef<any>(null);
  const [isOrbitEnabled, setIsOrbitEnabled] = useState(true);
  // const [isPlayModalOpen, setIsPlayModalOpen] = useState(false);
  // const [isARMode, setIsARMode] = useState(false);
  const [xrStore] = useState(() => createXRStore());
  // const [arError, setARError] = useState<string | null>(null);
  // const [isARSupported, setIsARSupported] = useState(false);
  const [gltfCache, setGltfCache] = useState<Record<string, THREE.Group>>({});
  const [currentLesson, setCurrentLesson] = useState<Lesson | null>(null);
  const currentLessonRef = useRef(currentLesson);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastpage] = useState(1);
  const [partsInventory, setPartsInventory] = useState<{
    [key: string]: number;
  }>({});
  const partsInventoryRef = useRef(partsInventory);

  // useEffect(() => {
  //   if ('xr' in navigator && navigator.xr) {
  //     navigator.xr.isSessionSupported('immersive-ar').then(setIsARSupported);
  //   } else {
  //     console.log('WebXR is not supported in this browser');
  //     setIsARSupported(false);
  //   }
  // }, []);

  useEffect(() => {
    setCurrentLesson(null);
    const urlParams = new URLSearchParams(window.location.search);
    const lessonParam = urlParams.get('lessonId');
    const userIDParam = urlParams.get('userId');
    const lessonNumber = lessonParam ? parseInt(lessonParam) : 1;
    const userID = userIDParam ? parseInt(userIDParam) : 1;

    // fetch(`/lessons/${lessonNumber}.json`)
    // .then(response => response.json())
    // .then(data => setPartsData(data))
    // .catch(error => {
    //   console.error('Error loading lesson:', error);
    //   // Load default lesson (0.json) if there's an error
    //   fetch('/lessons/0.json')
    //     .then(response => response.json())
    //     .then(data => setPartsData(data));
    // });
    const initialRenderData = async () => {
      // TODO: Get data from the server

      const response = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/${lessonNumber}/${userID}?page=${page}`,
      });
      setCurrentPage(Number(response.data?.data?.pagination?.current_page));
      setLastpage(Number(response.data?.data?.pagination?.last_page));
      const responseData = response.data.data.items;
      setPartsData(responseData?.inventory_data || responseData?.lesson_data);
      currentLessonRef.current = responseData?.lesson_data;
      setTimeout(() => {
        setCurrentLesson(responseData?.lesson_data);
      }, 2000);
      const newBlocksData: any = [];
      responseData?.blocks.forEach(async (d: any) => {
        const filename = d.url;
        let gltfModel: THREE.Group;
        if (gltfCache[filename]) {
          gltfModel = gltfCache[filename].clone();
        } else {
          try {
            gltfModel = await loadGLTF(filename);
            setGltfCache(prev => ({
              ...prev,
              [filename]: gltfModel.clone(),
            }));
          } catch (error) {
            console.error(`Error loading GLB model: ${filename}`, error);
            return;
          }
        }
        const file = d.url.split('/models/')[1];
        const data = {
          ...d,
          gltf: gltfModel,
          description: file.split('.glb')[0],
        };
        newBlocksData.push(data);
      });

      setCanvasBlocks(newBlocksData);
    };

    initialRenderData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const setPartsData = (data: any) => {
    const inventory = data?.pieces?.reduce(
      (
        acc: {[key: string]: number},
        piece: {filename: string; quantity: number},
      ) => {
        acc[piece.filename] = piece.quantity;
        return acc;
      },
      {},
    );

    setPartsInventory(inventory || data);
    partsInventoryRef.current = inventory || data;
  };

  const loadGLTF = useCallback((filename: string) => {
    return new Promise<THREE.Group>((resolve, reject) => {
      new GLTFLoader().load(
        filename,
        gltf => resolve(gltf.scene),
        undefined,
        reject,
      );
    });
  }, []);

  // const addBlockToCanvas = useCallback(
  const addBlockToCanvas = async (filename: string) => {
    if (partsInventory[filename] > 0) {
      let gltfModel: THREE.Group;

      if (gltfCache[filename]) {
        gltfModel = gltfCache[filename].clone();
      } else {
        try {
          gltfModel = await loadGLTF(filename);
          setGltfCache(prev => ({
            ...prev,
            [filename]: gltfModel.clone(),
          }));
        } catch (error) {
          console.error(`Error loading GLB model: ${filename}`, error);
          return;
        }
      }

      const file = filename.split("/models/")[1]
      const newModel: CanvasBlocks = {
        id: `${filename}-${Date.now()}`,
        url: filename,
        position: findFreePosition3D(),
        rotation: [0, 0, 0],
        gltf: gltfModel,
        description: file.split(".glb")[0]
      };
      console.log(`Added ${filename} to canvas with id ${newModel.id}`);
      setPartsInventory(prev => {
        const partsinventoryObj = {
          ...prev,
          [filename]: prev[filename] - 1,
        };
        setCanvasBlocks(prevBlocks => {
          const newBlocks = [...prevBlocks, newModel];
          callAddBlocksUpdate(newBlocks, partsinventoryObj);
          return newBlocks;
        });
        partsInventoryRef.current = partsinventoryObj;
        return partsinventoryObj;
      });
    }
  };
  //   },
  //   [gltfCache, loadGLTF, partsInventory],
  // );

  const callAddBlocksUpdate = (newBlocks: any, partsinventoryObj: any) => {
    const urlParams = new URLSearchParams(window.location.search);
    const lessonParam = urlParams.get('lessonId');
    const userIDParam = urlParams.get('userId');
    const lessonNumber = lessonParam ? parseInt(lessonParam) : 1;
    const userID = userIDParam ? parseInt(userIDParam) : 1;
    const newBlocksData = newBlocks.map((d: any) => {
      return {id: d.id, position: d.position, rotation: d.rotation, url: d.url};
    });

    fetch(`/lessons/${lessonNumber}.json`)
      .then(r => r.json())
      .then(data => {
        axios({
          method: 'post',
          url: END_POINT,
          data: {
            lesson_id: lessonNumber,
            user_id: userID,
            items: {
              blocks: newBlocksData,
              inventory_data: partsinventoryObj,
              lesson_data: data,
            },
          },
        })
          .then(function (response) {
            console.log('response::::', response);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
  };

  const findFreePosition3D = (): [number, number, number] => {
    let x = 0,
      y = 1,
      z = 0;
    let attempts = 0;
    const maxAttempts = 100;

    while (attempts < maxAttempts) {
      const isBlockNear = (block: BlockNear, x: number, y: number, z: number) => 
        Math.abs(block.position[0] - x) < 1 &&
        Math.abs(block.position[1] - y) < 1 &&
        Math.abs(block.position[2] - z) < 1;
      
      // eslint-disable-next-line no-loop-func
      if (!canvasBlocks.some(block => isBlockNear(block, x, y, z))) {
        return [x, y, z];
      }
      x += 1;
      if (x > 5) {
        x = 0;
        z += 1;
      }
      if (z > 5) {
        z = 0;
        y += 1;
      }
      attempts++;
    }
    return [x, y, z];
  };

  const updateBlockPosition = (
    index: number,
    newPosition: [number, number, number],
  ) => {
    setCanvasBlocks(prevBlocks => {
      const updatedBlocks = [...prevBlocks];
      updatedBlocks[index].position = newPosition;
      // callAddBlocksUpdate(updatedBlocks, partsInventory);
      return updatedBlocks;
    });
  };

  const callUpdateBlockPosition = (
    index: number,
    newPosition: [number, number, number],
  ) => {
    const updatedBlocks = [...canvasBlocks];
    updatedBlocks[index].position = newPosition;
    callAddBlocksUpdate(updatedBlocks, partsInventory);
  };

  // const changeView = useCallback(
  //   (view: 'top' | 'bottom' | 'left' | 'right' | 'initial') => {
  //     if (orbitControlsRef.current) {
  //       const controls = orbitControlsRef.current;
  //       switch (view) {
  //         case 'top':
  //           controls.reset();
  //           controls.setAzimuthalAngle(0);
  //           controls.setPolarAngle(0);
  //           break;
  //         case 'bottom':
  //           controls.reset();
  //           controls.setAzimuthalAngle(0);
  //           controls.setPolarAngle(Math.PI);
  //           break;
  //         case 'left':
  //           controls.reset();
  //           controls.setAzimuthalAngle(-Math.PI / 2);
  //           controls.setPolarAngle(Math.PI / 2);
  //           break;
  //         case 'right':
  //           controls.reset();
  //           controls.setAzimuthalAngle(Math.PI / 2);
  //           controls.setPolarAngle(Math.PI / 2);
  //           break;
  //         case 'initial':
  //           controls.reset();
  //           break;
  //       }
  //     }
  //   },
  //   [],
  // );

  const handleCanvasClick = () => {
    // setSelectedBlock(null);
  };

  const handleDeleteBlock = useCallback(
    (index: any) => {
      console.log(`Attempting to delete block at index: ${index}`);

      // Retrieve the block to be deleted before updating the state
      // const deletedBlock = canvasBlocks[index];
      const deletedBlock = canvasBlocks.filter((item)=> item.id === index);
      if (deletedBlock.length === 0) {
        console.log(`Invalid index: ${index}. No block deleted.`);
        return;
      }
      // Update inventory separately to ensure it's called only once
      setPartsInventory(prevInventory => {
        const currentQuantity = prevInventory[deletedBlock[0].url] || 0;
        const newQuantity = currentQuantity + 1;
        const partsinventoryObj = {
          ...prevInventory,
          [deletedBlock[0].url]: newQuantity,
        };

        // Remove the block from canvasBlocks
        setCanvasBlocks(prevBlocks => {
          const newBlocks = prevBlocks.filter((item, i) => item.id !== index);
          callAddBlocksUpdate(newBlocks, partsinventoryObj);
          return newBlocks;
        });

        partsInventoryRef.current = partsinventoryObj;
        return partsinventoryObj;
      });
    },
    [canvasBlocks],
  );

  const handleDuplicateBlock = useCallback((index: number) => {
    setCanvasBlocks(prevBlocks => {
      const blockToDuplicate = prevBlocks[index];
      const newPosition: [number, number, number] = [
        blockToDuplicate.position[0] + 1,
        blockToDuplicate.position[1],
        blockToDuplicate.position[2] + 1,
      ];
      const newBlock = {...blockToDuplicate, position: newPosition};
      const updatedBlocks = [...prevBlocks, newBlock];
      callAddBlocksUpdate(updatedBlocks, partsInventoryRef.current);
      return updatedBlocks;
    });
  }, []);

  const handleRotateBlock = useCallback((index: number, newRotation: [number, number, number]) => { //axis: 'x' | 'z' | 'y'
    setCanvasBlocks(prevBlocks => {
      const updatedBlocks = [...prevBlocks];
      const block = updatedBlocks[index];
      // const currentRotation = new THREE.Euler().fromArray(block.rotation);
      // const newRotation = new THREE.Euler().setFromQuaternion(
      //   new THREE.Quaternion()
      //     .setFromEuler(currentRotation)
      //     .multiply(
      //       new THREE.Quaternion().setFromEuler(
      //         new THREE.Euler(
      //           axis === 'x' ? Math.PI / 2 : 0,
      //           axis === 'y' ? Math.PI / 2 : 0,
      //           axis === 'z' ? Math.PI / 2 : 0,
      //           'XYZ',
      //         ),
      //       ),
      //     ),
      // );
      // block.rotation = newRotation.toArray().slice(0, 3) as [
      //   number,
      //   number,
      //   number,
      // ];

      block.rotation = newRotation;

      callAddBlocksUpdate(updatedBlocks, partsInventoryRef.current);
      return updatedBlocks;
    });
  }, []);

  // const handleARToggle = async () => {
  //   if (!isARSupported) {
  //     console.error('AR is not supported on this device');
  //     setARError('AR is not supported on this device');
  //     return;
  //   }
  //   setIsARMode(!isARMode);
  //   setARError(null);
  // };

  // const onARError = (error: any) => {
  //   console.error('AR error:', error);
  //   setARError(error.message || 'Failed to start AR session');
  // };

  const memoizedHandleDeleteBlock = useCallback(handleDeleteBlock, [
    handleDeleteBlock,
  ]);

  return (
    <div className="w-screen h-screen overflow-hidden">
      <Canvas
        camera={{position: initialCameraPosition.toArray(), fov: 100}}
        onClick={handleCanvasClick}
        shadows
        className="w-full h-full"
        gl={{alpha: false}}
        onCreated={({gl}) => {
          gl.setClearColor(new THREE.Color('#f0f0f0'));
        }}>
        <XR store={xrStore}>
          {
          // isARMode ? (
          //   <ARContent blocks={blocks} onError={onARError} />
          // ) : (
            <SceneContent
              // blocks={blocks}
              canvasBlocks={canvasBlocks}
              selectedBlock={selectedBlock}
              setSelectedBlock={setSelectedBlock}
              updateBlockPosition={updateBlockPosition}
              callUpdateBlockPosition={callUpdateBlockPosition}
              orbitControlsRef={orbitControlsRef}
              isOrbitEnabled={isOrbitEnabled}
              setIsOrbitEnabled={setIsOrbitEnabled}
              onDeleteBlock={memoizedHandleDeleteBlock}
              onDuplicateBlock={handleDuplicateBlock}
              onRotateBlock={handleRotateBlock}
            />
          // )
          }
        </XR>
      </Canvas>

      {/* Display AR errors */}
      {/* {arError && (
        <div className="absolute top-4 left-4 bg-red-500 text-white p-2 rounded">
          AR Error: {arError}
        </div>
      )} */}

      {/* Floating sidebar */}
      {/* <div className="absolute top-1/2 left-4 -translate-y-1/2 bg-white rounded-full shadow-lg py-6 px-4 w-20 flex flex-col items-center space-y-8">
        <FaMousePointer className="text-gray-600 text-2xl" />
        <FaSquare className="text-gray-600 text-2xl" />
        <FaPencilAlt className="text-gray-600 text-2xl" />
        <FaEye className="text-gray-600 text-2xl" />
        <FaExpand className="text-gray-600 text-2xl" />
        <button
          className={`btn btn-circle ${isARMode ? 'btn-primary' : 'btn-ghost'}`}
          onClick={handleARToggle}>
          <FaVrCardboard className="text-2xl" />
        </button>
        <div className="flex-grow" />
        <button
          className="mt-8 btn bg-green-500 hover:bg-green-600 w-full h-12 rounded-full"
          onClick={() => setIsPlayModalOpen(true)}>
          <FaPlay className="text-white text-xl" />
        </button>
      </div> */}

      {/* Title */}
      <div className="absolute top-4 left-28 text-2xl font-bold text-gray-800">
        &nbsp;
      </div>

      {/* Arrows top right */}
      {/* <CameraControls changeView={changeView} /> */}

      {/* Actions Model  */}
      <DndProvider backend={HTML5Backend}>
        <ActionsModel />
      </DndProvider>

      {/* model right icons*/}
      <div className="absolute top-4 right-4 z-10"></div>

      <div className="">hello</div>

      {/* Floating bottom bar */}
      {/* {currentLesson && ( */}
        <PartList
          parts={currentLesson?.pieces || []}
          inventory={partsInventory}
          onPartClick={addBlockToCanvas}
          setPage={setPage}
          currentPage={currentPage}
          lastPage={lastPage}
        />
      {/* )} */}

      {/* {isPlayModalOpen && (
        <PlayModal blocks={blocks} onClose={() => setIsPlayModalOpen(false)} />
      )} */}
    </div>
  );
};

export default Editor3D;
