import update from 'immutability-helper';
import React, {CSSProperties, useCallback} from 'react';
import {useDrop} from 'react-dnd';
import Card from './Card';
import type {XYCoord} from 'react-dnd';

const style: CSSProperties = {
  backgroundColor: 'white',
  border: '1px dashed gray',
  margin: '100px auto',
  minHeight: '300px',
  padding: '0 10px',
  textAlign: 'center',
  width: '100%',
  height: '100%',

};

export interface IListProps {
  cardList: IListData[];
  changeCardList: (list: IListData[]) => void;
}

export interface DragItem {
  type: string;
  id: string;
  top: number;
  left: number;
}

interface IListData {
  id: number;
  category: React.ReactNode;
  top?: number;
  left?: number;
}

enum ItemTypes {
  Card = 'card',
}

const List: React.FC<IListProps> = ({cardList, changeCardList}) => {
  const moveBox = useCallback(
    (id: string, left: number, top: number) => {
      const index = cardList.findIndex(box => box.id === Number(id)); // Convert id to number if necessary
      if (index !== -1) {
        // Create a new cardList with the updated box
        const updatedCardList = update(cardList, {
          [index]: {
            $merge: {left, top}, // Merge the new left and top values
          },
        });
        // Use changeCardList to update the state
        changeCardList(updatedCardList);
      }
    },
    [cardList, changeCardList],
  );

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.Card,
      drop(item: any, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        moveBox(item.id, left, top);
        return undefined;
      },
    }),
    [moveBox],
  );

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex === undefined) {
        const lessIndex = cardList.findIndex(
          (item: IListData) => item.id === -1,
        );
        changeCardList(
          update(cardList, {
            $splice: [
              [lessIndex, 1],
              [hoverIndex, 0, {category: 'Put here', id: -1}],
            ],
          }),
        );
      } else {
        const dragCard = cardList[dragIndex];
        changeCardList(
          update(cardList, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        );
      }
      // eslint-disable-next-line
    },
    [cardList, changeCardList],
  );

  return (
    <div style={style} ref={drop} className="model_details_width">
      {cardList.length <= 0 ? (
        <div style={{lineHeight: '60px'}}>Please put</div>
      ) : (
        cardList.map((item: IListData, index: number) => (
          <Card
            index={index}
            key={item.id}
            moveCard={moveCard}
            {...item}
            top={item.top}
            left={item.left}
          />
        ))
      )}
    </div>
  );
};

export default List;
