import React, { useState, useEffect, useRef} from 'react';
import {FaAlignLeft, FaChevronDown, FaTimes} from 'react-icons/fa';
import {FaRegCirclePlay, FaQuestion} from 'react-icons/fa6';
import Box from './dragging-object/Box';
import List from './dragging-object/List';
import {
  Light_1,
  Light_2,
  Light_3,
  Light_4,
  Light_5,
  Light_6,
  Light_7,
  Light_8,
  Light_9,
  Event_1,
  Control_1,
  Sound_1,
} from './actions-model/index';
import {ACTIONS_LIST, ACTIONS_LIST_3} from '../constants';
import WordblocksPreview from '../model/WordblocksPreview';
import HotKeys from './HotKeys';

// Define the shape of an action item
interface IActionItem {
  name: string;
  color: string;
}
interface IListData {
  id: number;
  category: React.ReactNode;
}

const DropdownExample: React.FC = () => {
  const [isOpenPlay, setIsOpenPlay] = useState(false);
  const [isOpenHotkey, setIsOpenHotkey] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState<string | null>(null);
  const [cardList, setCardList] = useState<any[]>([]);
  const [components, setComponents] = useState<{
    actions: IActionItem[];
    lightComponents: React.ElementType[];
    eventsComponents: React.ElementType[];
    controlComponents: React.ElementType[];
    soundComponents: React.ElementType[];
  }>({
    actions: [],
    lightComponents: [],
    eventsComponents: [],
    controlComponents: [],
    soundComponents: [],
  });

  const toggleDropdown = () => setIsOpen(prev => !prev);
  const toggleSubMenu = (index: string) =>
    setOpenSubMenuIndex(prev => (prev === index ? null : index));

  const toggleOpenPlay = () => {
    setIsOpenPlay(!isOpenPlay);
  };

  const toggleOpenHotkey = () => {
    setIsOpenHotkey(!isOpenHotkey);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const lessonParam = urlParams.get('lessonId');
    const isLesson3 = lessonParam && Number(lessonParam) === 3;

    setComponents({
      actions: isLesson3 ? ACTIONS_LIST_3 : ACTIONS_LIST,
      lightComponents: isLesson3
        ? [Light_1, Light_3, Light_8]
        : [
            Light_1,
            Light_2,
            Light_3,
            Light_4,
            Light_5,
            Light_6,
            Light_7,
            Light_8,
            Light_9,
          ],
      eventsComponents: isLesson3 ? [Event_1] : [],
      controlComponents: isLesson3 ? [Control_1] : [],
      soundComponents: isLesson3 ? [Sound_1] : [],
    });
  }, []);

  const renderBoxes = (components: React.ElementType[]) =>
    components.map((Component, index) => ({
      id: index + 1,
      category: <Component />,
    }));

  // Define the keys of boxesMap
  type BoxesMapKey = 'Light' | 'Events' | 'Control' | 'Sound';

  const boxesMap: Record<BoxesMapKey, IListData[]> = {
    Light: renderBoxes(components.lightComponents),
    Events: renderBoxes(components.eventsComponents),
    Control: renderBoxes(components.controlComponents),
    Sound: renderBoxes(components.soundComponents),
  };

  const changeCardList = (list: any[]) => {
    setCardList([...list]);
  };

 // Reference to the modal to detect outside clicks
 const modalRef = useRef<HTMLDivElement | null>(null);

  
    // Close the modal if the click is outside
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        // If the modal is open and the click happens outside the modalRef
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
          setIsOpenHotkey(false); // Close the modal
        }
      };

    if (isOpenHotkey) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Clean up event listener on component unmount or modal close
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpenHotkey]);


  return (
    <div className="absolute top-4 right-2 sm:right-5 left-2 sm:left-5 lg:left-auto z-10">
      <div className="flex space-x-6 justify-end mb-8">
      <button
        className={`btn btn-circle ${isOpenHotkey ? 'active_btn_hotkey' : ''}`}
        onClick={toggleOpenHotkey}
      >
        <FaQuestion style={{ width: '100%' }} />
      </button>
        <button
          className={`btn btn-circle ${isOpenPlay ? 'active_btn' : ''}`}
          onClick={toggleOpenPlay}>
          <FaRegCirclePlay style={{width: '100%'}} />
        </button>
        <button
          className={`btn btn-circle ${isOpen ? 'active_btn' : ''}`}
          onClick={toggleDropdown}>
          <FaAlignLeft />
        </button>
        <button
          onClick={() => {
            setIsOpen(false);
            setIsOpenHotkey(false);
          }}>
          <FaTimes />
        </button>
      </div>

      {isOpen && (
        <div className="model_box">
          <div className="model_list">
            <span className="text-2xl pb-5 lg:text-[2.5rem] font-extralight block sticky z-[9] top-0 bg-white text-midnight-black">
              Actions
            </span>
            <ul>
              {components.actions.map((item, index) => (
                <li key={index} className={`model_item ${item?.color}`}>
                  <button
                    className="model_items_wrapper"
                    onClick={() => toggleSubMenu(item?.name)}>
                    <span className="model_radio_box"></span>
                    {item?.name}
                    <FaChevronDown
                      className={`transition-transform ml-auto ${
                        openSubMenuIndex === item?.name ? 'rotate-180' : ''
                      }`}
                    />
                  </button>
                  {openSubMenuIndex === item.name && (
                    <ul className="sub_model_list">
                      <li className="mt-5">
                        {boxesMap[item.name as BoxesMapKey]?.map(box => (
                          <Box
                            key={box.id}
                            {...box}
                            cardList={cardList}
                            changeCardList={changeCardList}
                          />
                        ))}
                      </li>
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="model_details shape-blue">
            <span className="text-2xl pb-5 lg:text-[2.5rem] font-extralight block sticky z-[9] top-0 bg-white text-midnight-black">
              Logic
            </span>
            <List cardList={cardList} changeCardList={changeCardList} />

            {/* example of component  */}
            {/* <div className=" flex gap-3 justify-center">
              <SelectColorPalette
                id={1}
                dropdownOpen={DropdownOpen}
                isDropdownOpen={isDropdownOpen}
              />
              <SelectQuantity
                id={2}
                dropdownOpen={DropdownOpen}
                isDropdownOpen={isDropdownOpen}
              />
              <SelectMultiGrid
                id={3}
                dropdownOpen={DropdownOpen}
                isDropdownOpen={isDropdownOpen}
              />
              <SelectRotation
                id={4}
                dropdownOpen={DropdownOpen}
                isDropdownOpen={isDropdownOpen}
              />
              <SelectLoader
                id={5}
                dropdownOpen={DropdownOpen}
                isDropdownOpen={isDropdownOpen}
              />
              <SelectWordFilter
                id={6}
                dropdownOpen={DropdownOpen}
                isDropdownOpen={isDropdownOpen}
              />
            </div> */}
            {/* use this icon  */}
            {/* <div className=" flex gap-3 mt-3 justify-center">
              <i className="icon_hand">
                <PiHandPalm />
              </i>
              <i className="icon_music">
                <CgMusicNote />
              </i>
              <i className="icon_radio">
                <IoRadioButtonOnOutline />
              </i>
            </div> */}
          </div>
        </div>
      )}
      {isOpenPlay && <WordblocksPreview onClose={() => setIsOpenPlay(false)} />}

      {isOpenHotkey && (
        <div ref={modalRef}>
          <HotKeys onClose={() => setIsOpenHotkey(false)} />
        </div>
      )}
   
    </div>
  );
};

export default DropdownExample;
