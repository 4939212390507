import React from 'react';
import {FiArrowLeft} from 'react-icons/fi';

interface hotKeysProps {
  onClose: () => void;
}

const HotKeys: React.FC<hotKeysProps> = () => {
  
  return (
    <div className="model_box_hotkey">
      <h2 className="text-3xl font-extralight text-midnight-black mb-8">
        Hotkeys
      </h2>
      <ul>
        <li className="flex gap-3 items-center pb-4 mb-4 border-b capitalize border-creamy-white text-base font-normal text-midnight-black">
          <span className="border border-midnight-black w-6 uppercase h-6 flex items-center justify-center">
            E
          </span>
          Move
        </li>
        <li className="flex gap-3  items-center pb-4 mb-4 border-b capitalize border-creamy-white text-base font-normal text-midnight-black">
          <span className="border border-midnight-black uppercase w-6 h-6 flex items-center justify-center">
            R
          </span>
          Rotate
        </li>
        <li className="flex gap-1 justify-between  items-center capitalize text-base font-normal text-midnight-black">
          <button className="flex items-center gap-2 border-midnight-black  border  py-1 px-[0.625rem] text-base font-normal text-midnight-black">
            <FiArrowLeft />
            Backspace
          </button>
          <span className="text-base font-normal text-midnight-black">
            Remove pieces
          </span>
        </li>
      </ul>
    </div>
  );
};

export default HotKeys;
