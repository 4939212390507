import React, {useState} from 'react';
import {FaChevronDown} from 'react-icons/fa';

// interface IListProps {
//   id: number;
//   dropdownOpen: (item: any) => void;
//   isDropdownOpen: number;
// }

const Select_Word_filter = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [word, setWord] = useState("A");

  const toggleDropdown = () => {
    setShowDropdown(prevState => !prevState);
  };

  return (
    <div className="select_word_Grid">
      <div className="dropdown_wrapper">
        <div role="button" className=" dropdown_btn" onClick={toggleDropdown}>
          <i className="select_word_name">{word}</i>
          <i className="dropdown_arrow">
            <FaChevronDown />
          </i>
        </div>
        {showDropdown && (
          <ul className="dropdown_content">
            <li className="word_content_wrapper">
              <span className={`select_word_name ${word === "A" ? "active" : ""}`} onClick={() => setWord("A")}>A</span>
              <span className={`select_word_name ${word === "B" ? "active" : ""}`} onClick={() => setWord("B")}>B</span>
              <span className={`select_word_name ${word === "c" ? "active" : ""}`} onClick={() => setWord("c")}>c</span>
              <span className={`select_word_name ${word === "D" ? "active" : ""}`} onClick={() => setWord("D")}>D</span>
              <span className={`select_word_name ${word === "E" ? "active" : ""}`} onClick={() => setWord("E")}>E</span>
              <span className={`select_word_name ${word === "F" ? "active" : ""}`} onClick={() => setWord("F")}>F</span>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Select_Word_filter;
