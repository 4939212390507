import React, {useState} from 'react';
import {FaChevronDown} from 'react-icons/fa';

const Select_All = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isAll, setAll] = useState("All");

  const toggleDropdown = () => {
    setShowDropdown(prevState => !prevState);
  };

  return (
    <div className="select_word_Grid">
      <div className="dropdown_wrapper">
        <div role="button" className=" dropdown_btn" onClick={toggleDropdown}>
          <i className="select_control_name">{isAll}</i>
          <i className="dropdown_arrow">
            <FaChevronDown />
          </i>
        </div>
        {showDropdown && (
          <ul className="dropdown_content">
            <li className=" dropdown_content_remove_grid">
            <span className={`select_control_name ${isAll === "All" ? "active" : ""}`} onClick={() => setAll("All")}>All</span>
            <span className={`select_control_name ${isAll === "This stack" ? "active" : ""}`} onClick={() => setAll("This stack")}>This stack</span>
            <span className={`select_control_name ${isAll === "and exit program" ? "active" : ""}`} onClick={() => setAll("and exit program")}>and exit program</span>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Select_All;
