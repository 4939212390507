import React, {useState} from 'react';
import {FaChevronDown} from 'react-icons/fa';

const Select_Play = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [play, setPlay] = useState("Lorem");

  const toggleDropdown = () => {
    setShowDropdown(prevState => !prevState);
  };

  return (
    <div className="select_word_Grid">
      <div className="dropdown_wrapper">
        <div role="button" className=" dropdown_btn" onClick={toggleDropdown}>
          <i className="select_control_name active">{play}</i>
          <i className="dropdown_arrow">
            <FaChevronDown />
          </i>
        </div>
        {showDropdown && (
          <ul className="dropdown_content  ">
            <li className=" dropdown_content_remove_grid">
              <span className={`select_control_name ${play === "Lorem" ? "active" : ""}`} onClick={() => setPlay("Lorem")}>Lorem</span>
              <span className={`select_control_name ${play === "Library" ? "active" : ""}`} onClick={() => setPlay("Library")}>Library</span>
              <span className={`select_control_name ${play === "Upload..." ? "active" : ""}`} onClick={() => setPlay("Upload...")}>Upload...</span>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Select_Play;
