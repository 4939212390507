import React from 'react';
import {DragSourceMonitor, useDrag} from 'react-dnd';
import {IListProps} from './List';

let id = 1;
interface IListData {
  id: number;
  category: React.ReactNode;
  top?: number;
  left?: number;
}

interface IDraggableListData extends IListData {
  top: number;
  left: number;
}

enum ItemTypes {
  Card = 'card',
}

const Box: React.FC<IListData & IListProps> = ({
  category,
  cardList,
  changeCardList,
}) => {
  const box = {
    category,
  };
  const [, drag] = useDrag({
    type: ItemTypes.Card,
    item() {
      const useless = cardList.find((item: IListData) => item.id === -1);
      if (!useless) {
        changeCardList([{category: 'Put here', id: -1}, ...cardList]);
      }
      return box;
    },
    end(_: unknown, monitor: DragSourceMonitor) {
      const uselessIndex = cardList.findIndex(
        (item: IListData) => item.id === -1,
      );

      if (monitor.didDrop()) {
        const droppedItem = monitor.getItem() as IListData;
        const newItem: IDraggableListData = {
          ...droppedItem,
          id: id++,
          top: 5 * id,
          left: 5 * id,
        };
        const newCardList = [...cardList];
        newCardList.splice(uselessIndex, 1, newItem);
        changeCardList(newCardList);
      } else {
        const newCardList = [...cardList];
        newCardList.splice(uselessIndex, 1);
        changeCardList(newCardList);
      }
    },
  });
  return <div ref={drag} className='w-fit bg-transparent rounded-md shadow-none'>{category}</div>;
};

export default Box;
